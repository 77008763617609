import React, {  useRef } from "react";
import styled from "styled-components";
import road from "../../assets/RoadmapStrokecolor.png"

const Section = styled.section`
  min-height: 150vh;
  width: 100%;
  background-image: linear-gradient( #6dd997, #aea6d7);
  position: relative;
  display: inline-block;
  overflow: inherit;
  

`;


const Titleim = styled.img`
width: 35rem;
  text-transform: capitalize;
  color: #593373;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem auto;
  padding-top: 10px;
  padding-bottom: 20px;



  @media (max-width: 40em) {
    width: 20rem;
    padding-bottom: 10px;
    margin: 5rem auto;


  }
`;

const Contieni = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: #593373;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  width: fit-content;
  padding-top: 10px;
  padding-bottom: 50px;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;


const Container = styled.div`
  width: 80%;
  height: 100vh;
  margin: 0 auto;
  
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 64em) {
    width: 70%;
  }
  @media (max-width: 48em) {
    width: 80%;
  }

  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 
    margin-top: 150px;
    margin-bottom: 100px;
}
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    margin-top: 0;
    margin-bottom: 0;
}
`;
const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;


`;

const Items = styled.ul`
  list-style: none;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: lightblue; */

  @media (max-width: 48em) {
    width: 80%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: center;
    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 50px 0 50px 0;
      text-align: center;

      @media (max-width: 48em) {
        border-radius: 0 50px 0 50px;
      text-align: center;
        p {
          border-radius: 0 40px 0 40px;

        }
      }
    }
    p {
      border-radius: 40px 0 40px 0;
    }
  }
  & > *:nth-of-type(2n) {
    justify-content: end;
    @media (max-width: 48em) {
      justify-content: center;
    }
    div {
      border-radius: 0 50px 0 50px;
      text-align: center;

      
    }
    p {
      border-radius: 0 40px 0 40px;
    }
  }
`;
const Item = styled.li`
width: 80%;
  height: 100%;

  @media (max-width: 48em) {
    width: 30vh;
    justify-content: center !important;
  }
`;


const Box = styled.p`
  height: fit-content;
  background-color: ${(props) => props.theme.carouselColor};
  color: #300151;
  padding: 1rem;
  position: relative;
  border: 1px solid #300151;
`;
const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color:    color: #300151;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
  }
`;
const Text = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontsm};
  color: #300151;

  font-weight: 400;
  margin: 0.5rem 0;
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const RoadMapItem = ({ title, subtext, addToRef }) => {
  return (
    <Item ref={addToRef}>
    
        <Box>
          <SubTitle>{title} </SubTitle>
          <Text>{subtext}</Text>
        </Box>
      
    </Item>
  );
};

const Roadmap = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];
  
  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  

  return (
    <>
    <Section id="roadmap">
      <div style={{marginBottom: '5px'}}>

      <Titleim src={road}></Titleim>
      </div>
      <Container>
      <SvgContainer>
        </SvgContainer>
        
        <Items>
          

          <RoadMapItem
            addToRef={addToRefs}
            title="Lonfo Launch - Q3 2022"
            subtext="Launch on Lonfo.net, Twitter, Discord and build a community based on Lonfo mood.
            Raid 2 Earn LONFO tokens will allow you to enter into exclusive premint raffles. Prizes are Lonfo freemint, Sol NFTs and more."
          />

          <RoadMapItem
            addToRef={addToRefs}
            title="Lonfo Unleashed - Q4 2022"
            subtext="Unleashing of 100 Founding Lonfos.
            Aftermarket listing.
            STAKING your Lonfo for LONFO tokens will allow you to enter into exclusive raffles. Prizes are Lonfo and Sol NFTs, clothing and more."
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Lonfo brand - Q1 2023"
            subtext="High quality CLOTHING BRAND made in Italy purchasable with LONFO tokens.
            Lonfo CARTOON animations and books for kids."
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Updates Coming Soon"
            subtext=""
          />
              
        </Items>
      
      </Container>
      <Contieni style={{width: '90%', fontSize: '14px', paddingBottom: '25px'}}>
    <p style={{margin: '40px', textAlign: 'center'}}>
    *Please note that the road map is subject to change based on the Lonfo DAO decisions going forward.
    </p>     
    </Contieni> 
    </Section>
  
    </>
  );
};

export default Roadmap;
