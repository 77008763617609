import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
// import Banner from './Banner'
import Logo from './Logo'
import logu from "../assets/LonfoLogoC.png"

import Instagram from '../Icons/Instagram'
import Twitter from '../Icons/Twitter'
import Loading from './Loading'
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
const Banner = lazy(() => import("./Banner"));


const Section = styled.section`
min-height: 50vh;
width: 100vw;
background-image: linear-gradient( #aea6d7 , white);
position: relative;
color: #593373;

display: flex;
/* justify-content: center; */
/* align-items: center; */
flex-direction: column;
`

const Container = styled.div`
width: 75%;
margin: 2rem auto;
display: flex;
justify-content: space-between;
align-items: center;

border-bottom: 1px solid ${(props) => props.theme.text};

@media (max-width: 48em) {
width: 90%;

h1{
font-size: ${props => props.theme.fontxxxl};

}
}
`
const Left = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 48em) {
width: 100%;
}
`

const IconList = styled.div`
display: flex;
align-items: center;
margin: 1rem auto;

&>*{
  padding-right: 0.5rem;
  transition: all 0.2s ease;

  &:hover{
    transform: scale(1.2);
  }
}
`
const MenuItems = styled.ul`
list-style:none;
width: 50%;
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(3, 1fr);
grid-gap: 1rem;

@media (max-width: 48em) {
display: none;
}
`
const Item = styled.li`
width: fit-content;
cursor: pointer;

&::after{
  content: ' ';
  display: block;
  width: 0%;
  height: 2px;
  background: #593373;
  transition: width 0.3s ease;
}
&:hover::after{
  width: 100%;
}
`

const Bottom = styled.div`
width: 75%;
margin: 0 auto;
display: flex;
justify-content: space-between;
align-items: center;

a{
  text-decoration:underline;
}
@media (max-width: 48em) {
flex-direction: column;
width: 100%;

span{
  margin-bottom: 1rem;
}
}
`

const Footer = () => {

  const scrollTo = (id) => {

    let element = document.getElementById(id);
  
   
  }
  
  return (
    <Section>
      

      <Container>

    <Left> 
    <Logo logoSrc={logu} />
    <IconList>
     
      <a href="https://twitter.com/LonfoNFT" target='_blank' 
      rel="noopener noreferrer"
      aria-label='twitter'
      >
        <Twitter />
        <TwitterFollowButton
    screenName={'LonfoNFT'}
  />
      </a>

    </IconList>

    </Left>

    <MenuItems>
    <Item onClick={() => scrollTo('home')}  >Home</Item>

          <a href='https://lonfo-raffle.vercel.app/' target='_blank' rel="noreferrer"><Item style={{color: '#b4ea72'}} > Raffle</Item>
          </a>
          <a href='https://stake.cardinal.so/lonfo' target='_blank' rel="noreferrer"><Item style={{color: '#b4ea72'}} > Staking</Item>
          </a>
          <a href='https://lonfo.gitbook.io/lonfo-white-paper/' target='_blank'><Item style={{color: '#b4ea72'}} > WhitePaper</Item></a>
          <a></a>
          <a>  <TwitterHashtagButton
    tag={'Lonfo'}
  /></a>


    </MenuItems>

      </Container>
      <Bottom>
        <span>
          &copy; {new Date().getFullYear()} Lonfo. All rights reserved.
        </span>
        {/*
        <span>
        <img class="u-align-center u-image u-image-default u-image-1" src="https://nftsolana.io/logo/simple-black-min.png" alt="" data-image-width="624" data-image-height="129" data-href="https://nftsolana.io/" data-target="_blank" width={'100px'}  />
          
        </span>
        */}
      </Bottom>
    </Section>
  )
}

export default Footer