import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
// import Carousel from '../Carousel'
import Loading from '../Loading';
import verso1 from '../../assets/1.mp3'
import verso2 from '../../assets/2.mp3'

import verso3 from '../../assets/3.mp3'
import verso4 from '../../assets/4.mp3'
import verso5 from '../../assets/5.mp3'

import logotipo from '../../assets/LonfoScritta.png'



const Carousel = lazy(() => import("../Carousel"));

const Section = styled.section`
min-height: 100vh;
width: 100%;
background-image: linear-gradient( #ff857a , #ffe272);
display: flex;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;

`
const Container = styled.div`
width: 75%;
margin: 0 auto;
/* background-color: lightblue; */

/* #ff857a */

display: flex;
justify-content: center;
align-items: center;
@media (max-width: 70em){
  width: 85%;
}

@media (max-width: 64em){
  width: 100%;
  flex-direction: column;

  &>*:last-child{
    width: 80%;
  }
}
@media (max-width: 40em){
  

  &>*:last-child{
    width: 90%;
  }
}
`
const Box = styled.div`
width: 50%;
height: 100%;
min-height: 60vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 40em){
  min-height: 50vh;
}
`
 
const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  color: #593373;
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxl};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontlg};

}
`
const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: #593373;
  align-self: flex-start;
  width: 80%;
margin: 1rem auto;
font-weight:400;
@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}

`

const ButtonContainer = styled.div`
  margin: 1rem auto;
  display: flex;
  align-self: flex-start;

  
button{
  margin: 0 auto;
}
}

`

const Btn = styled.button`
display: inline-block;
background-color: #300151;
color: #ffe689;
outline: none;
border: none;

font-size: ${props => props.theme.fontsm};
padding: 0.9rem 2.3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: relative;
&:hover{
    transform: scale(0.9);
}

&::after{
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${props => props.theme.text};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
}

&:hover::after{
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
}
`

let vr1 = new Audio(verso1)
let vr2 = new Audio(verso2) 
let vr3 = new Audio(verso3) 
let vr4 = new Audio(verso4) 
let vr5 = new Audio(verso5) 


const versi = [vr1,vr2,vr3,vr4,vr5];
const random = Math.floor(Math.random() * versi.length);
let rand =  versi[random]
let versos = versi[random];
let start = () => {
  versi[0].play()
  versi.sort(function () {
    return Math.random() - 0.5;
  })
}



const About = () => {
  return (
    <Section id="about">
      <Container>
        <Box> 
        <Suspense fallback={<Loading />}>
          <Carousel /> </Suspense> </Box>
        <Box> 

        <Title>
      <img src={logotipo} width='50%' style={{margin: '-5px'}}></img>
        <br /> 
        </Title>
        <SubText>
        Lonfo is a cartoon character who is part of a high quality,        <br /> 
 Italian clothing brand and linked to animations and children's books.

        </SubText>
        <ButtonContainer>
          <Btn onClick={start}>Get in the LONFO mood</Btn>
        </ButtonContainer>
         </Box>

      </Container>
    </Section>
    
  )
}

export default About