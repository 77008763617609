import React, { useRef } from 'react'
import styled from 'styled-components';


import lonfogamb from '../../assets/Nfts/tim/GAMBERO.jpeg';
import lonfojack from '../../assets/Nfts/tim/JACK.jpeg';
import lonfodeddu from '../../assets/Nfts/tim/DEDDU.jpeg';
import lonfooli from '../../assets/Nfts/tim/OLLY.jpeg';
import lonfoerika from '../../assets/Nfts/tim/ERIKA.jpeg';
import lonfolion from '../../assets/Nfts/tim/LION.jpeg';
import lonfonhile from '../../assets/Nfts/tim/NHILE.jpeg';
import lonfoPHAT from '../../assets/Nfts/tim/PHAT.jpeg';
import lonfocorda from '../../assets/Nfts/tim/LUCA.jpeg';
import anyz from '../../assets/Nfts/tim/anyz.png'
import cloudz from '../../assets/Nfts/tim/0.png'
import jayjay from '../../assets/Nfts/tim/jayjay.jpeg'





import tim from '../../assets/TeamStrokecolor.png';


// import ConfettiComponent from '../Confetti';

// write css linear gradient 5% #ffe272 and rest #aea6d7




const Section = styled.section`
min-height: 100vh;
width: 100vw;
background-image:  linear-gradient( #ffe272, #6dd997, #aea6d7);
position: relative;
overflow: hidden;
`

const Titleim = styled.img`
width: 25rem;
  text-transform: capitalize;
  color: #593373;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem auto;
  padding-top: 10px;
  padding-bottom: 20px;



  @media (max-width: 40em) {
    width: 15rem;
    padding-bottom: 10px;
    margin: 2rem auto;


  }
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;

@media (max-width: 64em){
width: 80%;
}
@media (max-width: 48em){
width: 90%;
justify-content: center;
}
`

const Item = styled.div`
width: calc(20rem - 4vw);
padding: 1rem 0;
color: #300151;
margin: 2rem 1rem;
position: relative;
z-index:5;

backdrop-filter: blur(4px);

border: 2px solid #300151;
border-radius: 20px;

&:hover{
  img{
    transform: translateY(-2rem) scale(1.2);
  }
}

@media (max-width: 30em){
width: 70vw;
}

`

const ImageContainer = styled.div`
width: 80%;
margin: 0 auto;
background-color:${props => props.theme.carouselColor};
border: 1px solid #300151;
padding: 1rem;

border-radius: 20px;
cursor: pointer;

img{
  width: 100%;
  height: auto;
transition: all 0.3s ease;

}
`

const Name = styled.h2`
font-size: ${props => props.theme.fontlg};
display: flex;
align-items: center;
justify-content: center;
text-transform: uppercase;
color: #300151;
margin-top: 1rem;
`

const Position = styled.h2`
font-size: ${props => props.theme.fontmd};
display: flex;
text-align: center;
align-items: center;
justify-content: center;
text-transform: capitalize;
color: #593373;
font-weight:400;
`

const MemberComponent = ({ img, name = " ", position = " " }) => {

  return (
    <Item>
      <ImageContainer>
        <img width={500} height={400} src={img} alt={name} style={{ 'border-radius': '10%' }} />
      </ImageContainer>
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Item>
  )
}


const Team = () => {
  const ref = useRef(null);

  return (
    <Section ref={ref} id="team">
      <Titleim src={tim}></Titleim>
      <Container>
        <a href='https://twitter.com/Gambero_sol' target='_blank' rel="noreferrer" ><MemberComponent img={lonfogamb} name="GAMBERO.SOL" position="founder" /></a>
        <MemberComponent img={lonfojack} name="JACK" position="artist" />
        <a href='https://twitter.com/SolDeddu' target='_blank' rel="noreferrer" >< MemberComponent img={lonfodeddu} name="DEDDU NFT" position="Dev" /> </a>
        <MemberComponent img={lonfooli} name="OLLY" position="Clothing Manager" />
        <a href='https://instagram.com/phatdsgn?igshid=YmMyMTA2M2Y=' target='_blank' rel="noreferrer" ><MemberComponent img={lonfoPHAT} name="PHAT" position="Graphic and motion designer" /> </a>
        <a href='https://twitter.com/jayjaynft4' target='_blank' rel='noreferrer'><MemberComponent img={jayjay} name="JAYJAY" position="Collab Manager" /></a>
        

        <MemberComponent img={lonfocorda} name="LUCA CORDA" position="Illustrator, 2D animator and graphic designer" />

        <MemberComponent img={lonfoerika} name="ERIKA" position="Community Manager" />
        <MemberComponent img={lonfolion} name="LION" position="Community Manager" />
        <MemberComponent img={lonfonhile} name="NHILE" position="Moderator" />



      </Container>
    </Section>
  )
}

export default Team