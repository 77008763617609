import React, {  useRef } from "react";
import styled from "styled-components";
import road from "../../assets/RoadmapStrokecolor.png"

const Section = styled.section`
  min-height: 20vh;
  width: 100%;
  background-image: linear-gradient( #ffe272, #6dd997, #aea6d7);
  position: relative;

  overflow: inherit;
  

`;


const Titleim = styled.img`
width: 35rem;
  text-transform: capitalize;
  color: #593373;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem auto;
  padding-top: 10px;
  padding-bottom: 20px;



  @media (max-width: 40em) {
    width: 20rem;
    padding-bottom: 10px;
    margin: 5rem auto;


  }
`;

const Contieni = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: #593373;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  width: fit-content;
  padding-top: 10px;
  padding-bottom: 50px;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;


const Container = styled.div`
  width: 80%;
  height: 100vh;
  margin: 0 auto;
  
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 64em) {
    width: 70%;
  }
  @media (max-width: 48em) {
    width: 80%;
  }

  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 
    margin-top: 150px;
    margin-bottom: 100px;
}
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    margin-top: 0;
    margin-bottom: 0;
}
`;
const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;


`;

const Items = styled.ul`
  list-style: none;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: lightblue; */

  @media (max-width: 48em) {
    width: 80%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: center;
    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 50px 0 50px 0;
      text-align: center;

      @media (max-width: 48em) {
        border-radius: 0 50px 0 50px;
      text-align: center;
        p {
          border-radius: 0 40px 0 40px;

        }
      }
    }
    p {
      border-radius: 40px 0 40px 0;
    }
  }
  & > *:nth-of-type(2n) {
    justify-content: end;
    @media (max-width: 48em) {
      justify-content: center;
    }
    div {
      border-radius: 0 50px 0 50px;
      text-align: center;

      
    }
    p {
      border-radius: 0 40px 0 40px;
    }
  }
`;
const Item = styled.li`
width: 80%;
  height: 100%;

  @media (max-width: 48em) {
    width: 30vh;
    justify-content: center !important;
  }
`;


const Box = styled.p`
  height: fit-content;
  background-color: ${(props) => props.theme.carouselColor};
  color: #300151;
  padding: 1rem;
  position: relative;
  border: 1px solid #300151;
`;
const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color:    color: #300151;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
  }
`;
const Text = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontsm};
  color: #300151;

  font-weight: 400;
  margin: 0.5rem 0;
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;


const Zobia = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];
  
  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  

  return (
    <>
    <Section id="roadmap">
      
      <Container>
      <SubTitle>
      Introducing Lonfo, a project that brings together high-quality Italian fashion and stunning animations to create a global, recognizable, Italian brand backed by simplistic, yet elegant art and high quality clothing. Quality is the main drive behind Lonfo, and holding a Lonfo NFT represents a stake in the Lonfo ecosystem and community.

Our main mint collection encompasses 3333 unique characters, called “Lonfos”, These Lontos are rare and elusive, the making each NFT unique and unable to be replicated, perfect for an online identity. Holding a Lonfo NFT will grant you exclusive rewards and gated access into the Lonfo brand and ecosystem. 
      </SubTitle>
      
      </Container>
    </Section>
  
    </>
  );
};

export default Zobia;
