import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';



import styled from 'styled-components';





// import ConfettiComponent from '../Confetti';


const Section = styled.section`

background-color:  #aea6d7;
position: relative;
overflow: hidden;
text-align: center;

`



const Container = styled.div`
background-color:  #aea6d7;

text-align: center;
margin: 5%;
padding: 5%;
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;

@media (max-width: 64em){
width: 80%;
}
@media (max-width: 48em){
width: 90%;
justify-content: center;
}

`







const Tuitt = () => {


    return (
<Section> 
      <Container>
  
      <TwitterTimelineEmbed
  sourceType="profile"
  screenName="LonfoNFT"
  options={{height: 400, width: 300}}
/>


  
  </Container>
  </Section>

        
    )
}

export default Tuitt;