import React from 'react';
import './Logo.css';

function Logo({ logoSrc }) {
  return (
    <div className="logo-container">
      <img src={logoSrc} alt="Logo" className="logo-image" />
    </div>
  );
}

export default Logo;
