import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components';
import logotipo from '../../assets/LonfoScritta.png'
import Accordion from 'react-bootstrap/Accordion';



const Section = styled.section`
min-height: 80vh;
height: auto;
width: 100vw;

background: url(https://i.ibb.co/jrDCLsP/Lonfo-wallpaper-sky.png);
background-size: cover;
position: relative;


color: #300151;
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`




const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }
  @media (max-width: 48em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`
const Box = styled.div`
width: 45%;
@media (max-width: 64em){
  width: 90%;
  align-self: center;
  }

`

const Faq = () => {

  const ref = useRef(null);
  useLayoutEffect(() => {

    



  }, [])

  return (
    <Section ref={ref} id="faq">
      <img src={logotipo} width='30%' style={{ textAlign: 'center' }} alt='Lonfo'></img>


      <Container>

        <Box>
          <Accordion  >
            <Accordion.Item eventKey="0" bsPrefix='text-povia' >
              <Accordion.Header  >
                STATE OF MIND
              </Accordion.Header>
              <Accordion.Body >
              The Lonfo is confident; it acts without being influenced by anything.
It does not let events affect its mood. Lonfo is just itself.
Learn its philosophy and get in the Lonfo mood.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion  >
            <Accordion.Item eventKey="1" bsPrefix='text-povia'>
              <Accordion.Header>CARTOON
              </Accordion.Header>
              <Accordion.Body>
                Lonfo animations and books for kids will be released. Holders can contribute to write Lonfo stories and adventures.

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion  >
            <Accordion.Item eventKey="2" bsPrefix='text-povia'>
              <Accordion.Header>
                STAKING
              </Accordion.Header>

              <Accordion.Body>
                By hybernating your Lonfo you will earn our utility token $LONFO.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>        </Box>
        <Box>
        <Accordion  >
            <Accordion.Item eventKey="3" bsPrefix='text-povia' >
              <Accordion.Header  >DAO
                </Accordion.Header>
                <Accordion.Body style={{ color: '#593373'}}>
            The Lonfo DAO manages the community wallet made by 1/3 mint and aftermarket revenue and contributes to grow the brand.
            </Accordion.Body>
            </Accordion.Item>
          </Accordion> 
          <Accordion  >
            <Accordion.Item eventKey="4" bsPrefix='text-povia'>
              <Accordion.Header>RAFFLES
                </Accordion.Header >
                <Accordion.Body>
            Lonfo Raffles everyday. Prizes are Lonfo and Sol NFTs, clothing and irl event pass.
            </Accordion.Body>
            </Accordion.Item>
          </Accordion> 
          <Accordion  >
            <Accordion.Item eventKey="5" bsPrefix='text-povia'>
              <Accordion.Header>CLOTHING BRAND
                </Accordion.Header>
                <Accordion.Body>
            100% made in Italy, high quality materials.
            </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Box>
      </Container>
    </Section>
  )
}

export default Faq